import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { isMobileOnly } from 'react-device-detect'
import _ from 'lodash'
import posed from 'react-pose'
import poseProps from '../pose-props'

import SEO from '../components/seo'
import MobileSplash from '../components/mobile-splash'

class Page extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: undefined,
            isVisible: false,
        }
    }

	componentWillMount() {
        this.setData()

        const { optionsData, imagesData, isMobile } = this.props

        // load imagesdata if it doesn't exist
        if (!optionsData || !imagesData) {
            const optionsData = JSON.parse(
                this.props.data.wordpressOptions.options
            )
            const imagesData = JSON.parse(this.props.data.wordpressImages.images)

            this.props.dispatch({
                type: 'LOAD_DATA',
                optionsData: optionsData,
                imagesData: imagesData,
            })
        }

        if (isMobile !== isMobileOnly) {
            this.props.dispatch({
                type: 'UPDATE_MOBILE',
                isMobile: isMobileOnly
            })
        }
    }
    
    componentDidMount() {
        if (typeof window !== `undefined`) {
            window.scrollTo(0, 0)
        }

        this.setState({
            isVisible: true,
        })
    }

	setData = (reset) => {
		this.setState({
            data: this.props.pageContext
		})
    }
    
    renderMobileImages = () => {
        const { data } = this.state
        const slug = this.props.pageContext.slug

        const filteredImages = _.filter(
            data.images,
            function(o) {
                return _.some(o.categories, function(category) {
                    return category === slug
                })
            }
        )

        const images = filteredImages.map((image, i) => {
            return (
                <MobileImage
                    key={i}
                >
                    <Image src={image.acf.image.sizes.large} />
                </MobileImage>
            )
        })

        return (
            <MobileImages>
                {images}
            </MobileImages>
        )
    }

    render() {
        const { data, isVisible } = this.state
        const { isMobile, optionsData } = this.props
        let voucherLink = undefined
        if (optionsData) {
            voucherLink = this.props.optionsData.options.gift_voucher_link
        }

        return (
            <Wrapper>
                {/* SEO Title */}
                {data && <SEO title={data.title} />}

                <PoseContent pose={isVisible ? 'visible' : 'hidden'}>
                    {/* Mobile Images */}
                    {isMobile && data && data.images && (
                        this.renderMobileImages()
                    )}

                    {/* PDF Link */}
                    {data && data.acf.menu && (
                        <ExternalLink
                            href={data.acf.menu.menu_pdf}
                            target="_blank"
                        >
                            {data.acf.menu.menu_title}
                        </ExternalLink>
                    )}

                    {/* Text */}
                    {data && data.acf.text && (
                        <Info
                            dangerouslySetInnerHTML={{__html: data.acf.text}}
                        />
                    )}

                    <BottomNav>
                        {/* Gift Voucher Link */}
                        {voucherLink && (
                            <Fragment>
                                <ExternalLink
                                    href={voucherLink}
                                    target="_blank"
                                >
                                    Gift Voucher
                                    
                                </ExternalLink>
                                <span>&#160;/&#160;</span>
                            </Fragment>
                        )}

                        {/* Back Button */}
                        <BackLink to="/">
                            Back
                        </BackLink>
                    </BottomNav>

                </PoseContent>

                {/* Mobile Only */}
                {isMobile && <MobileSplash />}
            </Wrapper>
        )
    }
}

const Info = styled.div``
const BottomNav = styled.div`
    ${tw`flex flex-row justify-center`}    
`
const ExternalLink = styled.a``
const BackLink = styled(Link)``

const Wrapper = styled.div`
    ${tw`h-full`}
`

const PoseContent = styled(posed.div(poseProps.content))`
    ${tw`flex sm:block flex-col justify-between items-center text-center h-full p-6 sm:p-0`}

    ${Info} {
        ${tw`max-w-md mx-auto py-10 lg:px-4 text-xl lg:text-lg leading-relaxed`}
    }

    ${ExternalLink}, ${BackLink}, span {
        ${tw`inline-block font-heading uppercase leading-none`}
        font-size: 100px;
        letter-spacing: 2.01px;

        @media (max-width: 768px) {
            font-size: 80px;
        }
    }
`

const MobileImages = styled.div`
    ${tw`hidden sm:block mb-8`}
`
const MobileImage = styled.div`
    ${tw`w-full`}
    height: 300px;
    min-height: 300px;
`
const Image = styled.img`
    ${tw`w-full h-full object-cover`}
`

Page.propTypes = {
    optionsData: PropTypes.object,
    imagesData: PropTypes.object,
    isMobile: PropTypes.bool,
}

const mapStateToProps = state => ({
    optionsData: state.data.optionsData,
    imagesData: state.data.imagesData,
    isMobile: state.mobile.isMobile,
})

export default connect(mapStateToProps)(Page)

export const pageQuery = graphql`
    query {
        wordpressOptions {
            options
        }
        wordpressImages {
            images
        }
    }
`